<template>
  <div>
    <div class="containerx mx-5">
      <v-row>
        <v-col cols="12">
          <div class="d-flex flex-row flex-grow-1">
            <div class=" d-flex flex-column flex-grow-1">
              <h2 class=" ft font-weight-bold font-size-lg blue-grey--text">
                Exports &amp; Downloads
              </h2>
              <p class=" ft font-weight-medium">
                Get access to all reports, view, customize and export them
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex flex-row flex-grow-1">
            <div class=" d-flex flex-column">
              <v-autocomplete
                :items="[...branches]"
                item-text="name"
                item-value="id"
                label="Choose Branch"
                class=" ft  font-weight-medium font-size-sm"
                dense
                flat
                v-model="branchId"
                solo
                background-color="#e8e8e8"
              >
                <template #item="{item}">
                  <span
                    class=" text-uppercase ft font-weight-medium font-size-sm"
                    >{{ item.name }}</span
                  >
                </template>
              </v-autocomplete>
            </div>

            <v-autocomplete
              :items="reports"
              item-text="name"
              item-value="id"
              label="Select the type of reports to download"
              class=" ft ml-1 font-weight-medium font-size-sm"
              hide-details
              dense
              flat
              v-model="report"
              solo
              background-color="#e8e8e8"
            >
              <template #item="{item}">
                <span class=" ft font-weight-medium font-size-sm">{{
                  item.name
                }}</span>
              </template>
              <template #selection="{item}">
                <v-chip class="box-shadow-light" label color="primary" small>
                  <span
                    class=" ft font-weight-medium"
                    style="font-size: 12px;"
                    >{{ item.name }}</span
                  >
                </v-chip>
              </template>
            </v-autocomplete>
            <div
              v-if="report === 'LUBES_GENERAL_MONTHLY_REPORTS'"
              class=" mx-1 d-flex flex-column"
            >
              <v-autocomplete
                :items="[...monthlyDates]"
                item-text="label"
                item-value="value"
                label="Choose Month/Duration"
                class=" ft  font-weight-medium font-size-sm"
                dense
                flat
                v-model="lubesMonthlyDate"
                solo
                background-color="#e8e8e8"
              >
                <template #item="{item}">
                  <span
                    class=" text-uppercase ft font-weight-medium font-size-sm"
                    >{{ item.label }}</span
                  >
                </template>
              </v-autocomplete>
            </div>
            <div v-else class="d-flex flex-column">
              <v-menu
                v-if="report !== 'CUSTOMERS_LIST'"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                class="ft font-weight-medium"
                min-width="auto"
                :nudge-left="80"
                :nudge-bottom="6"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-model="dates"
                    v-bind="attrs"
                    v-on="on"
                    label
                    :close="dates.length === 2"
                    @click:close="dates = []"
                    :color="dates.length > 0 ? 'primary' : 'white'"
                    class="  ft mx-1 text-uppercase font-weight-medium"
                    style="padding: 19px !important;"
                  >
                    <i class="material-icons-outlined font-size-md mr-2"
                      >event</i
                    >
                    {{ dates.length > 0 ? dateRangeText : "Choose date range" }}
                  </v-chip>
                </template>
                <v-date-picker
                  class="ft font-weight-medium font-size-sm"
                  v-model="dates"
                  range
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    class=" ft font-weight-medium"
                    text
                    color="primary"
                    @click="(menu = false), (dates = [])"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    @click="filteredByDates"
                    class="ft font-weight-medium"
                    color="primary"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-autocomplete
                v-else
                :items="customerTypeList"
                label="Choose Option"
                class=" ft ml-2 font-weight-medium font-size-sm"
                dense
                item-value="value"
                item-text="text"
                flat
                v-model="customerType"
                solo
                background-color="#e8e8e8"
              >
                <template #item="{item}">
                  <span
                    class=" text-uppercase ft font-weight-medium font-size-sm"
                    >{{ item.text }}</span
                  >
                </template>
              </v-autocomplete>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div
            v-if="
              (report === 'CUSTOMERS_LIST' &&
                records.length > 0 &&
                branchId !== null) ||
                (records.length > 0 &&
                  branchId !== null &&
                  dates.length > 1 &&
                  report !== null)
            "
            class="d-flex flex-column flex-grow-1"
          >
            <div
              v-if="report !== 'LUBE_SALES'"
              class="d-flex flex-row flex-grow-1 justify-end mb-2"
            >
              <v-btn
                outlined
                small
                @click.stop="downloadFile('xlsx')"
                color="success"
                class="mr-1"
                ><span
                  class=" ft font-weight-medium font-size-sm text-uppercase"
                  >Export As Excel</span
                ></v-btn
              >
              <v-btn
                outlined
                @click.stop="downloadFile('csv')"
                small
                color="success"
                ><span
                  class=" ft font-weight-medium font-size-sm text-uppercase"
                  >Export As CSV</span
                ></v-btn
              >
            </div>

            <v-data-table
              v-if="report !== 'LUBE_SALES'"
              :loading="listLoading"
              :items="records"
              :headers="titles"
              class=" ft box-shadow-light font-weight-medium font-size-sm"
              :footer-props="{
                itemsPerPageOptions: [15, 30]
              }"
            >
              <template #item.productDeliveries="{item}">
                <template
                  v-if="
                    item.productDeliveries && item.productDeliveries.length > 0
                  "
                >
                  <div class="d-flex flex-row flex-wrap">
                    <v-chip
                      class="box-shadow-light mb-1"
                      label
                      x-small
                      v-for="(delivery, index) in item.productDeliveries"
                      :key="`del-${item.chequeNmber}-${index}`"
                    >
                      <span class=" ft font-weight-medium font-size-sm"
                        >{{ delivery.branchName }}- Qty:
                        {{ delivery.quantity }}</span
                      >
                    </v-chip>
                  </div>
                </template>
              </template>
              <template #item.amount="{item}">
                <span> {{ item.amount | currencyFormat }}</span>
              </template>
              <template #item.date="{item}">
                <span> {{ item.date | dateFormat }}</span>
              </template>
              <template #item.totalAmountBefore="{item}">
                <span> {{ item.totalAmountBefore | currencyFormat }}</span>
              </template>
              <template #item.currentTotalAmount="{item}">
                <span> {{ item.currentTotalAmount | currencyFormat }}</span>
              </template>
              <template #item.creditPurchasesAmount="{item}">
                <span> {{ item.creditPurchasesAmount | currencyFormat }}</span>
              </template>
              <template #item.name="{item}">
                <span> {{ item.name }}</span>
              </template>
              <template #item.email="{item}">
                <span v-if="!item.email">N/A</span>
                <a
                  target="_blank"
                  v-else
                  class=" text-decoration-underline cursor-pointer"
                  :href="`mailto:${item.email}`"
                >
                  {{ item.email }}</a
                >
              </template>
            </v-data-table>
            <template v-else>
              <div class="d-flex flex-row flex-grow-1 flex-wrap">
                <div
                  v-for="(record, index) in records"
                  :key="index"
                  class="d-flex flex-grow-1 flex-column"
                >
                  <div class=" d-flex flex-row mt-1">
                    <v-chip
                      small
                      label
                      color="primary"
                      class="box-shadow-light"
                    >
                      <span class=" ft font-weight-medium font-size-md">
                        {{ record[0] | dateFormat }}
                      </span>
                    </v-chip>
                  </div>
                  <v-data-table
                    :items="record[1]"
                    class=" ft font-weight-medium  ma-1 font-size-sm box-shadow-light"
                    :headers="titles"
                  >
                  </v-data-table>
                </div>
              </div>
            </template>
          </div>
          <EmptyPage
            v-else
            :title="
              branchId !== null &&
              dates.length > 1 &&
              report !== null &&
              records.length === 0
                ? `No records found within this date range - ${dates.join('~')}`
                : 'Exports your reports for external use'
            "
            :subTitle="
              'Have a view of your chosen report, export them to excel, csv etc.'
            "
            imageWidth="200px"
            :image="require('@/assets/search.png')"
          >
            <template #action v-if="listLoading">
              <v-progress-circular indeterminate />
            </template>
          </EmptyPage>
        </v-col>
      </v-row>
    </div>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import SnackBarMixins from "../../mixins/SnackBarMixins";
import { getMonthsBetweenDateRange } from "../utils/resolvers";
import { currencyFormat } from "../../filters/currencyFormat";
import { REPORTS_DROPDOWN } from "../../config/constants";
export default {
  name: "ExportsAndDownloads",
  components: {
    EmptyPage: () => import(`../../components/EmptyPage`),
    SnackBar: () => import(`../../components/SnackBar`)
  },
  computed: {
    ...mapGetters({
      branches: "branches/getAllBranches",
      listLoading: "getIsListLoading",
      records: "exportReport/getAllReports",
      titles: "exportReport/getTitles"
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    }
  },
  filters: {
    dateFormat(value) {
      return moment(value).isValid()
        ? moment(value).format("Do MMMM, YYYY")
        : value;
    },
    currencyFormat
  },
  mixins: [SnackBarMixins],
  data() {
    return {
      menu: false,
      dates: [],
      branchId: null,
      report: null,
      monthlyDates: [],
      lubesMonthlyDate: null,
      customerType: "CUSTOMERS",
      customerTypeList: [
        { text: "INDIVIDUALS", value: "CUSTOMERS" },
        { text: "SUPPLIERS", value: "SUPPLIERS" },
        { text: "BUSINESSES", value: "BUSINESSES" }
      ],
      reports: REPORTS_DROPDOWN
    };
  },
  watch: {
    lubesMonthlyDate(value) {
      if (this.branchId && this.report) {
        this.dates = [value, value]; // set both start and end date to the same date
        this.$store.dispatch("exportReport/records", {
          startDate: value,
          endDate: value,
          action: this.report,
          branchId: this.branchId
        });
      }
    },
    report(value) {
      if (value === "LUBES_GENERAL_MONTHLY_REPORTS" && this.lubesMonthlyDate) {
        this.dates = [this.lubesMonthlyDate, this.lubesMonthlyDate];
        this.$store.dispatch("exportReport/records", {
          startDate: this.lubesMonthlyDate,
          endDate: this.lubesMonthlyDate,
          action: this.report,
          branchId: this.branchId
        });
        return;
      }

      if (value === "CUSTOMERS_LIST" && this.branchId) {
        this.$store.dispatch("exportReport/records", {
          action: value,
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
          customerType: this.customerType,
          branchId: this.branchId
        });
        return;
      }

      if (this.branchId && this.dates.length === 2) {
        const [startDate, endDate] = this.dates;
        this.$store.dispatch("exportReport/records", {
          startDate,
          endDate,
          action: value,
          ...(value === "DAILY_CREDIT_COLLECTIONS_BY_CUSTOMERS" && {
            type: "credit-collection" //indicator to just remove a field from the exported file
          }),
          branchId: this.branchId
        });
      }
    },
    branchId(value) {
      if (
        this.report === "LUBES_GENERAL_MONTHLY_REPORTS" &&
        this.lubesMonthlyDate
      ) {
        this.dates = [this.lubesMonthlyDate, this.lubesMonthlyDate];
        this.$store.dispatch("exportReport/records", {
          startDate: this.lubesMonthlyDate,
          endDate: this.lubesMonthlyDate,
          action: this.report,
          branchId: value
        });
        return;
      }
      if (this.report === "CUSTOMERS_LIST" && this.branchId) {
        this.$store.dispatch("exportReport/records", {
          action: this.report,
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
          customerType: this.customerType,
          branchId: value
        });
        return;
      }
      if (this.report && this.dates.length === 2) {
        const [startDate, endDate] = this.dates;
        this.$store.dispatch("exportReport/records", {
          startDate,
          endDate,
          action: this.report,
          ...(this.report === "DAILY_CREDIT_COLLECTIONS_BY_CUSTOMERS" && {
            type: "credit-collection" //indicator to just remove a field from the exported file
          }),
          branchId: value
        });
      }
    },
    dates(value) {
      if (this.report && value.length === 2 && this.branchId) {
        const [startDate, endDate] = value;
        if (moment(startDate).isAfter(moment(endDate))) {
          this.$store.dispatch(
            "showSnackBar",
            {
              snackbar: true,
              message: `Invalid date range provided`,
              status: "red"
            },
            { root: true }
          );
          return;
        }
        this.$store.dispatch("exportReport/records", {
          startDate,
          endDate,
          action: this.report,
          ...(this.report === "DAILY_CREDIT_COLLECTIONS_BY_CUSTOMERS" && {
            type: "credit-collection" //indicator to just remove a field from the exported file
          }),
          branchId: this.branchId
        });
      }
    },
    customerType(value) {
      if (this.branchId && this.report) {
        this.$store.dispatch("exportReport/records", {
          action: this.report,
          customerType: value,
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
          branchId: this.branchId
        });
      }
    }
  },
  methods: {
    filteredByDates() {
      const [startDate, endDate] = this.dates;
      if (moment(startDate).isAfter(moment(endDate))) {
        this.$store.dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `Invalid date range provided`,
            status: "red"
          },
          { root: true }
        );
        return;
      }
      this.$refs.menu.save(this.dates);
    },
    downloadFile(ext) {
      const file =
        this.reports.find(report => report.id === this.report)?.fileName ?? "";
      const branchName =
        this.branches
          .find(({ id }) => id === this.branchId)
          ?.name?.slice(0, 3) ?? "";
      const [startDate, endDate] =
        this.dates.length > 0
          ? this.dates
          : [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]; // setting default dates array
      const fileName =
        this.dates.length > 0
          ? `${branchName}${file}${startDate.replace(
              /-+/g,
              ""
            )}-${endDate.replace(/-+/g, "")}.${ext}`
          : `${branchName}-${this.customerType ?? file}.${ext}`;
      this.$store.dispatch("exportReport/records", {
        startDate,
        endDate,
        action: this.report,
        branchId: this.branchId,
        customerType: this.customerType,
        download: true,
        ...(this.report === "DAILY_CREDIT_COLLECTIONS_BY_CUSTOMERS" && {
          type: "credit-collection" //indicator to just remove a field from the exported file
        }),
        fileName: fileName?.replace(/[@&~!#%*()\s]+/g, "")
      });
    }
  },
  created() {
    this.$store.dispatch("branches/list");
    this.monthlyDates = getMonthsBetweenDateRange(
      moment("01-2021", "MM-YYYY"),
      moment(new Date(), "MM-YYYY").add(3, "years")
    );
  }
};
</script>
